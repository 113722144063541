<template>
    <div class="details fs-unmask">
        <modal-out-of-stock />

        <div v-if="singleProduct.products">
            <div v-if="!hideBackToResults" class="details__back">
                <button
                    class="details__back-link"
                    @click="goToProduct"
                    title="Back to Results"
                >
                    {{ $t("message.backToResults") }}
                </button>
            </div>
            <div class="row details__header">
                <div class="col-12 col-md-6">
                    <!-- We are using product[0] because on a stag this info will be the same -->
                    <h1 class="details__heading">{{ tireBrand }}</h1>
                    <h2 class="details__heading">{{ tireName }}</h2>

                    <rating
                        slot="reviews"
                        v-if="getPowerReviews.enabled && modelID"
                        :modelID="modelID"
                    />
                </div>

                <ul class="col-6 details__list-mobile">
                    <li
                        v-for="(sectionName, index) in sectionNames"
                        @click="switchSection(index)"
                        :key="index"
                    >
                        <a class="details__list-mobile__anchor" :href="`#${sectionName}`" :aria-current="`#${sectionName}`">{{
                            $t(`message.${sectionName}`)
                        }}</a>
                    </li>
                </ul>

                <modal-tire-image
                    :isOpen="showTireImageModal"
                    @onClose="toggleTireImageModal"
                    :productSet="singleProduct"
                />

                <div class="col-5 offset-md-1 details__selected-img-wrapper">
                    <button
                        class="details__tire-img-button"
                        v-if="showImageButton"
                        @click.prevent="toggleTireImageModal"
                    >
                        <img
                            class="details__selected-img img-fluid"
                            :src="tireImg"
                            @error="hideImageButton"
                            :alt="tireImgAlt"
                        />
                        <IconSearch class="details__icon" />
                    </button>
                </div>
            </div>

            <hr class="details__border" />

            <div class="details__sections-wrapper">
                <!-- Tabs and tab content -->
                <div class="details__section">
                    <ul class="details__list">
                        <li
                            v-for="(sectionName, index) in sectionNames"
                            @click="switchSection(index)"
                            :key="index"
                            :class="checkActiveItem(index)"
                        >
                            <a
                                class="details__list__anchor"
                                :href="`#${sectionName}`"
                                :aria-current="activeSection === index"
                            >
                                {{ $t(`message.${sectionName}`) }}
                            </a>
                        </li>
                    </ul>
                    <!-- Features Section -->
                    <div id="features">
                        <feature-block
                            v-if="singleProduct.products"
                            :query="query"
                            :productSet="singleProduct"
                        />
                    </div>
                    <!-- /Features Section -->

                    <!-- Specifications -->
                    <div id="specs" class="details__specs-wrapper">
                        <specs
                            :tireSet="singleProduct.products"
                            :warrantyDoc="warrantyDoc"
                        ></specs>
                    </div>
                    <!-- /Specifications -->

                    <!-- Ratings & Reviews -->
                    <template v-if="getPowerReviews.enabled">
                        <h4 id="ratingsAndReviews">
                            Review Snapshot
                            <span class="small">
                                by
                                <a href="https://www.powerreviews.com">PowerReviews</a>
                            </span>
                        </h4>
                        <div id="review-display"></div>
                    </template>
                    <!-- /Ratings & Reviews -->

                    <!-- 24-Month Road Hazard Coverage -->
                    <div class="details__cms-ad" v-html="ad"></div>

                </div>
                <!-- Tabs and tab content -->

                <!-- Pricing Block -->
                <div class="details__shop-block col-5">
                    <tile-shop-block
                        v-if="singleProduct.products"
                        :query="query"
                        :colSize="12"
                        :isOnDetailPage="true"
                        :productSet="singleProduct"
                        :financing-disclaimer="financingDisclaimer"
                        :buttonColSize="'col-8'"
                        :statefulBtnClass="'button button--primary button--full'"
                        :isMobileInstallAvailable="isMobileInstallAvailable"
                    />

                    <div
                        v-if="advertisement.length"
                        class="details__cms-bridgestone-ad"
                        v-html="advertisement"
                    ></div>

                    <div class="details__cms-details" v-html="details"></div>
                </div>
            <!-- /Pricing Block -->
            </div>
        </div>

        <div v-else>
            <content-placeholder
                v-for="(n, i) in 3"
                :key="i"
                :rows="placeholderRows"
            />
        </div>

        <div
            v-if="selected.hasFinancingOTS && selected.financePartnerCode"
            class="details__finance-footer"
        >
            <p
                class="finance-disclaimer"
                v-html="estimatedPaymentDisclaimer"
            ></p>
        </div>
    </div>
</template>

<script>
import ModalTireImage from '@/components/ModalTireImage.vue';
import ContentPlaceholder from 'vue-content-placeholder';
import FeatureBlock from '@/components/DetailsFeatureBlock.vue';
import Rating from '@/components/TileRating.vue';
import TileShopBlock from '@/components/TileShopBlock.vue';
import Specs from '@/components/DetailSpecificationsList.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { productSkuEvent } from '@/utils/setDataLayer';
import { structuredProductQuery } from '@/utils/product-query-builder';
import IconSearch from '@/components/IconSearch';
import ModalOutOfStock from '@/components/ModalOutOfStock';
import { RudderstackProductsMixin } from '@/rudderstack/track/mixins/products';
import { RudderstackChatMixin } from '@/rudderstack/track/mixins/chat';

export default {
	title: 'Detail',

	props: {
		query: {
			type: Object,
			required: true
		}
	},

	mixins: [RudderstackProductsMixin, RudderstackChatMixin],

	components: {
		ModalTireImage,
		TileShopBlock,
		FeatureBlock,
		ContentPlaceholder,
		Rating,
		Specs,
		ModalOutOfStock,
		IconSearch
	},

	data() {
		return {
			activeSection: 0,
			modelID: '',
			reviewsSet: false,
			sectionNames: ['features', 'specs', 'roadHazard'],
			showImageButton: true,
			tireBrand: '',
			tireName: '',
			showTireImageModal: false,
			placeholderRows: [
				{
					height: '25px',
					boxes: [[0, '100px']]
				},
				{
					height: '25px',
					boxes: [
						[0, '100px'],
						['10%', 1]
					]
				},
				{
					height: '25px',
					boxes: [[0, '100px']]
				},
				{
					height: '25px',
					boxes: [
						[0, '100px'],
						['10%', 2]
					]
				},
				{
					height: '2rem',
					boxes: [[0, 0]]
				},
				{
					height: '1rem',
					boxes: [[0, 5]]
				},
				{
					height: '1rem',
					boxes: [[0, 0]]
				},
				{
					height: '1rem',
					boxes: [[0, '50%']]
				},
				{
					height: '1rem',
					boxes: [[0, 0]]
				},
				{
					height: '1rem',
					boxes: [[0, '10em']]
				}
			]
		};
	},

	methods: {
		...mapActions('productDetail', ['setProductOverview', 'setWarrantyDoc']),
		...mapActions('dealer', {
			selectDealer: 'select'
		}),
		...mapActions('products', ['getAllProducts', 'filterCompareItemsFromAllProducts', 'setSingleProduct']),
		...mapActions('content/details', {
			fetchDetailContent: 'fetchContent',
			fetchDetailDocs: 'fetchDocs'
		}),
		...mapActions('content/products', {
			fetchProductContent: 'fetchContent'
		}),
		...mapMutations('content/details', ['resetAdvertisementContent']),
		checkActiveItem(sectionID) {
			if (this.activeSection === sectionID) {
				return 'details__list__item details__list__item--active';
			}

			return 'details__list__item';
		},
		goToProduct() {
			this.$router.push({
				path: '/app/product',
				query: this.query
			});
		},
		hideImageButton() {
			this.showImageButton = false;
		},
		setReviews(product) {
			const shouldSetReviews = Boolean(product && this.getPowerReviews.enabled && !this.reviewsSet);

			if (shouldSetReviews) {
				window.POWERREVIEWS.display.render({
					api_key: this.getPowerReviews.key, // eslint-disable-line camelcase
					locale: 'en_US',
					merchant_group_id: this.getPowerReviews.group, // eslint-disable-line camelcase
					merchant_id: this.getPowerReviews.id, // eslint-disable-line camelcase
					page_id: product.modelID, // eslint-disable-line camelcase
					review_wrapper_url: '/app/details/review', // eslint-disable-line camelcase
					components: {
						ReviewDisplay: 'review-display'
					}
				});

				this.reviewsSet = true;
			}
		},
		setAdditionalProductDetails() {
			const product = this.singleProduct.products[0];

			productSkuEvent('productDetailsPageImpression', this.singleProduct);

			this.trackProductViewed();

			// Data that we intend to set once and reuse
			this.modelID = product.modelID;
			this.tireBrand = product.brand;
			this.tireName = product.catalogEntryDescription;

			// Actions to get product overview and warranty doc
			this.setProductOverview(product);
			this.setWarrantyDoc(product);

			// Local method to set powerreviews
			this.setReviews(product);

			this.fetchDetailDocs(product.brandCode);
		},
		switchSection(id) {
			this.activeSection = id;
		},
		toggleTireImageModal() {
			this.showTireImageModal = !this.showTireImageModal;
		},
		async refreshAllData() {
			this.fetchDetailContent(true);
			this.fetchProductContent(true);
			// TODO: Stop getting all products, here. When landing on detail,
			// our service should be able to look up JUST the one item we want.
			// PQR is unfortunaltely not set up that way at present.
			if (this.query.sku) {
				this.query.qty = this.qtySelected;
				await this.setSingleProduct({
					query: this.query,
					id: this.query.id,
					cid: this.query.cid
				}).then(() => {
					this.setAdditionalProductDetails();
				});
			} else {
				await this.getAllProducts(structuredProductQuery(this.query))
					.then(() => {
						if (this.noTires) {
							this.$router.push({
								path: '/app/no-products',
								query: this.query
							});
						} else {
							this.setSingleProduct({
								query: this.query,
								id: this.query.id,
								cid: this.query.cid
							}).then(() => {
								this.setAdditionalProductDetails();
							});
						}
					})
					.catch((err) => {
						throw new Error(err);
					});
			}
		}
	},

	computed: {
		...mapState('initial', ['phoneNumber', 'title', 'powerReviews']),
		...mapState('content/details', ['ad', 'details', 'advertisement']),
		...mapState('products', ['allProducts', 'noTires', 'selectedProdName', 'singleProduct', 'qtySelected']),
		...mapState('dealer', {	selectedDealer: 'selected' }),
		...mapState('dealer', ['selected']),
		...mapState('search', ['dealership_id']),
		...mapGetters('initial', ['getPowerReviews']),
		...mapGetters('productDetail', ['productOverview', 'warrantyDoc']),
		pageTitle() {
			let title = `${ this.$t('message.productDetailsPage') } | ${ this.tireBrand } ${ this.tireName } | ${ this.title }`;

			return title;
		},
		hideBackToResults() {
			// This check is used to determine if the "Back to Results" element should render
			// If Pirelli (cid), or if staggered and referred (likely via Dynamic Quote), do NOT render the element
			return this.query.cid || (this.query.referrer && this.singleProduct.products.length !== 1);
		},
		tireImg() {
			const tireId = this.singleProduct.products[0].imageId;

			return tireId ? `/content/drsg/b_xl_iso/${tireId}_b_xl.jpg` : '/content/drsg/b_iso/default.jpg';
		},
		tireImgAlt() {
			return `${this.tireBrand } ${ this.tireName}`;
		},
		isMobileInstallAvailable() {
			// eslint-disable-next-line no-undefined
			return this.selected.transports.find(transport => transport.id === 14) !== undefined;
		},
		estimatedPaymentDisclaimer() {
			if (!this.selectedDealer.financePartner) {
				return null;
			}

			return this.selectedDealer.financePartner.estimatedPaymentDisclaimer;
		},
		financingDisclaimer() {
			if (!this.selectedDealer.financePartner) {
				return null;
			}

			return this.selectedDealer.financePartner;
		},
		hasPowerReviews() {
			if (this.isProd) {
				return this.powerReviews.prod.enabled;
			}

			return this.powerReviews.dev.enabled;
		}
	},

	watch: {
		pageTitle(newTitle) {
			document.title = newTitle;
		},
		'$store.state.consumerInfo.userLanguage': function() {
			this.refreshAllData();
		},
		async qtySelected(newVal) {
			if (newVal) {
				try {
					await this.refreshAllData();

				} catch (err) {
					throw new Error(err);
				}
			}
		}
	},
	// eslint-disable-next-line complexity
	async created() {
		// Resets the state value holding ad content so it doesn't show when viewing a subsequent tires
		this.resetAdvertisementContent();

		if (!this.allProducts.length) {
			this.getAllProducts(structuredProductQuery(this.query))
				.then(() => {
					if (this.noTires) {
						this.$router.push({
							path: '/app/no-products',
							query: this.query
						});
					}
				})
				.catch((err) => {
					throw new Error(err);
				});
		} else {
			this.filterCompareItemsFromAllProducts();
		}

		if (!this.selectedDealer.websiteUID) {
			await this.selectDealer({
				uid: this.query.uid,
				make: this.query.make
			});
		}

		this.fetchDetailContent();
		this.fetchProductContent();

		this.$rudderstack.page(this.$route.meta.pageCategory, this.$route.meta.pageCategory, {
			...this.$route.query,
			'dealership_id': this.dealership_id
		});
	},
	beforeMount() {
		if (this.getPowerReviews.enabled) {
			this.sectionNames.splice(2, 0, 'ratingsAndReviews');
		}
	},
	mounted() {
		// Have to wait until "review-display" dom element is mounted before setAdditionalProductDetails
		// SetAdditionalProductDetails sets the product overview and warranty doc, and reviews if needed
		// If the setSingleProduct PQR fails, redirect to product page
		if (!this.singleProduct.products) {
			this.setSingleProduct({
				query: this.query,
				id: this.query.id,
				cid: this.query.cid
			})
				.then(() => {
					this.setAdditionalProductDetails();
				})
				.catch((err) => {
					this.$router.push({
						path: '/error/500',
						query: this.query
					});
					throw new Error(err);
				});
		} else {
			this.setAdditionalProductDetails();
		}
	}
};
</script>
