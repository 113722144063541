// this is the only option right now
const chatTypes = {
	live: 'Live'
};
const interactionTypes = {
	agent: 'Agent',
	customer: 'Customer'
};
const chatDefaultProperties = {
	'chat_type': chatTypes.live
};

export const RudderstackChatMixin = {
	methods: {
		// yes
		trackChatInitiated() {
			this.$rudderstack.track('CHAT_INITIATED');
		},

		// no
		trackChatSessionStarted() {
			this.$rudderstack.track('CHAT_SESSION_STARTED', {
				...chatDefaultProperties
			});
		},

		// yes, yes
		trackChatSessionInteraction(interactionType) {
			this.$rudderstack.track('CHAT_SESSION_INTERACTION', {
				...chatDefaultProperties,
				'interaction_type': interactionType
			});
		},

		// yes
		trackChatSessionClosed() {
			this.$rudderstack.track('CHAT_SESSION_CLOSED', {
				...chatDefaultProperties
			});
		}
	},

	created() {
		console.log('Rudderstack Chat Mixn - Created');
		// Callback to fire when the help button is clicked.
		document.addEventListener('onHelpButtonClick', () => {
			console.log('onHelpButtonClick');
			this.trackChatInitiated();
		});

		// Fired when the chat request is successful.
		document.addEventListener('onChatRequestSuccess', () => {
			console.log('onChatRequestSuccess');
			this.trackChatSessionStarted();
		});

		// Fired when the agent sends a message.
		document.addEventListener('onAgentMessage', () => {
			console.log('onAgentMessage');
			this.trackChatSessionInteraction(interactionTypes.agent);
		});

		// Fired when the chat visitor sends a message.
		document.addEventListener('onChasitorMessage', () => {
			console.log('onChasitorMessage');
			this.trackChatSessionInteraction(interactionTypes.customer);
		});

		// Fired when Embedded Service Chat has ended and the application is closed.
		document.addEventListener('afterDestroy', () => {
			console.log('afterDestroy');
			this.trackChatSessionClosed();
		});
	}
};
